import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import LocalStorageUtils from '../../../utils/local-storage';
import headersBuilder, { Method } from '../../../utils/request';

export const FETCH_CONFIG = 'FETCH_CONFIG';
export const FETCH_LANG_JSON = 'FETCH_LANG_JSON';
export const FETCH_CONFIG_SHARED = 'FETCH_CONFIG_SHARED';

export type FetchConfigAction = {
  type: string;
  config: any;
};

export type FetchLanguageJSONAction = {
  type: string;
  langFile: any;
};

const fetchConfig = (config: any): FetchConfigAction => {
  return {
    type: FETCH_CONFIG,
    config
  };
};

const fetchConfigShared = (config: any): FetchConfigAction => {
  return {
    type: FETCH_CONFIG_SHARED,
    config
  };
};

const fetchConfigAsync = () => {
  return (dispatch: Dispatch): void => {
    fetch('/conf.json').then((response) =>
      response
        .json()
        .then((json: any) => {
          LocalStorageUtils.putValueToLocalStorage('apiUrl', json?.api);
          LocalStorageUtils.putValueToLocalStorage(
            'hideRegisterButton',
            json.flags.hideRegisterButton.toString()
          );
          // PAMM-2341 TODO
          LocalStorageUtils.putValueToLocalStorage(
            'showRequestTab',
            json.flags.showRequestTab.toString()
          );
          LocalStorageUtils.putValueToLocalStorage(
            'showMmRegistrator',
            json.flags.showMmRegistrator.toString()
          );
          LocalStorageUtils.putValueToLocalStorage(
            'customColors',
            JSON.stringify(json.customColors)
          );
          LocalStorageUtils.putValueToLocalStorage(
            'customColorsGeneralUser',
            JSON.stringify(json.customColorsGeneralUser)
          );

          dispatch(fetchConfig(json));
        })
        .catch((error) => Errors.throwErrorMessage(error))
    );
  };
};

const fetchLanguageJSON = (langFile: any): FetchLanguageJSONAction => {
  return {
    type: FETCH_LANG_JSON,
    langFile
  };
};

export const fetchSharedSettingsAsync = (api: string) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/settings/common/shared`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((json: any) => {
        LocalStorageUtils.putValueToLocalStorage(
          'showRequestTab',
          json.RequestTab.toString()
        );
        dispatch(fetchConfigShared(json));
      })
      .catch((error) => Errors.throwErrorMessage(error));
  }
}

export const fetchLanguageJSONAsync = (lang: string) => {
  return (dispatch: Dispatch): void => {
    fetch(`/i18n/${lang}.json`)
      .then((response) => response.json())
      .then((json: any) => {
        dispatch(fetchLanguageJSON(json));
      })
      .catch((error) => Errors.throwErrorMessage(error));
  };
};

export default fetchConfigAsync;
