import { RequestStatus, RequestType } from '../types/requestTypes';
import LocalStorageUtils from './local-storage';

export enum Method {
  GET = 'GET',
  POST = 'POST'
}

const headersBuilder = (method: Method, body?: unknown): RequestInit => {
  return {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${LocalStorageUtils.getValueFromLocalStorage('jwt')}`
    }
  };
};

export const getRequestType = (request: any, translationKey?: boolean): string => {
  switch (request.Type) {
    case RequestType.ChangeBalance:
      if (request.Amount < 0) {
        return translationKey ? 'requests.type.Withdrawal' : 'Withdrawal';
      }
      
      return translationKey ? 'requests.type.Deposit' : 'Deposit';
    case RequestType.ChangeCredit:
      if (request.Amount < 0) {
        return translationKey ? 'requests.type.CreditOut' : 'CreditOut';
      }

      return translationKey ? 'requests.type.CreditIn' : 'CreditIn';
    case RequestType.ChangeFeeAccountsInfo:
      return translationKey ? 'requests.type.EditFeeAccountsInfo' : 'Edit Fee Accounts Info';
    case RequestType.Attach:
      return translationKey ? 'requests.type.Connect' : 'Connect';
    case RequestType.Detach:
      return translationKey ? 'requests.type.Disconnect' : 'Disconnect';
    default:
      return translationKey ? `requests.filters.byType.${request.Type.charAt(0).toLowerCase() + request.Type.slice(1)}` : request.Type;
  }
};

export const getRequestStatus = (request: any, translationKey?: boolean): string => {
  switch (request.Status) {
    case RequestStatus.Approved:
        return translationKey ? 'requests.status.approved' : 'Approved';
    case RequestStatus.Canceled:
        return translationKey ? 'requests.status.canceled' : 'Canceled';
    case RequestStatus.Completed:
      return translationKey ? 'requests.status.completed' : 'Completed';
    case RequestStatus.Error:
      return translationKey ? 'requests.status.error' : 'Error';
    case RequestStatus.Executing:
      return translationKey ? 'requests.status.executing' : 'Executing';
    case RequestStatus.New:
      return translationKey ? 'requests.status.new' : 'New';
    case RequestStatus.Planned:
      return translationKey ? 'requests.status.planned' : 'Planned';
    case RequestStatus.Rejected:
      return translationKey ? 'requests.status.rejected' : 'Rejected';
    default:
      return translationKey ? '' : '';
  }
};

export const getBootstrapTextColor = (type: string): string => {
  switch (type) {
      case RequestStatus.Executing:
      case RequestStatus.Planned: {
          return 'text-warning'
      }
      case RequestStatus.Approved:
      case RequestStatus.Completed: {
          return 'text-success'
      }
      case RequestStatus.Rejected:
      case RequestStatus.Error:
      case RequestStatus.Canceled: {
          return 'text-danger'
      }
      default: {
          return 'text-dark';
      }
  }
}

export default headersBuilder;
