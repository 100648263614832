import { Dispatch } from 'redux';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../setLoadingStatus';
import Errors from '../../../shared/validation/errors';
import { Master } from '../../../types/masterTypes';

export const FETCH_MASTER_DETAILS = 'FETCH_MASTER_DETAILS ';
export const FETCH_MASTER_DETAILS_DEFAULT = 'FETCH_MASTER_DETAILS_DEFAULT ';
export const RESET_MASTER_DETAILS = 'RESET_MASTER_DETAILS';


export type FetchDetailsAction = {
  type: string;
  masterDetails: Master;
}
export type FetchDetailsDefaultAction = {
  type: string;
  masterDetailsDefault: Master;
}

export const fetchMasterDetails = (master: Master): FetchDetailsAction => {
  return {
    type: FETCH_MASTER_DETAILS,
    masterDetails: master
  }
};


export const fetchMasterDetailsDefault = (master: Master): FetchDetailsDefaultAction => {
  return {
    type: FETCH_MASTER_DETAILS_DEFAULT,
    masterDetailsDefault: master
  }
};

export const resetMasterDetailsAC = () => ({ type: RESET_MASTER_DETAILS });


function fetchMasterDetailsAsync(api: string, masterId: number, notAdmin?: boolean) {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/masters/${masterId}?returnAccountOptionsValues=${!!notAdmin}`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((master: any) => {
        if (notAdmin === true) {
          dispatch(fetchMasterDetailsDefault(master))
        }
          dispatch(fetchMasterDetails(master))
      })
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  }
}

export default fetchMasterDetailsAsync;
