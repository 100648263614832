import {
  FETCH_INVESTORS,
  FetchInvestorsAction,
  RESET_DATA_INVESTORS,
  UPDATE_INVESTORS,
  SELECTED_LINKED_ACCOUNT,
  selectedLinkedAccountAction
} from '../../actions/investor/fetchInvestors';
import { Investor } from '../../../types/investorTypes';
import { PaginationInfo } from '../../../types/sharedTypes';
// TODO delete a field isSelected
export interface InvestorsState {
  investors: Array<Investor>;
  paginationInfo: PaginationInfo;
  isSelected: boolean;
}

const initialState: InvestorsState = {
  investors: [],
  paginationInfo: new PaginationInfo(),
  isSelected: false,
};

const investorsReducer = (
  state: InvestorsState = initialState,
  action: FetchInvestorsAction & selectedLinkedAccountAction
): InvestorsState => {
  switch (action.type) {
    case FETCH_INVESTORS:
      return {
        ...state,
        investors: action.investors,
        paginationInfo: action.paginationInfo
      };
    case RESET_DATA_INVESTORS:
      return {
        investors: [],
        paginationInfo: new PaginationInfo(),
        isSelected: false
      };
    case UPDATE_INVESTORS:
      return {
        ...state,
        investors: [...action.investors],
      };
    case SELECTED_LINKED_ACCOUNT:
      return {
        ...state,
        isSelected: action.isSelected,
      };

    // case INVESTORS_SORT_BY_EQUITY_UP:
    //   return {
    //     ...state,
    //     investors: state.investors.sort((a, b) => )
    //   }

    default:
      return state;
  }
};

export default investorsReducer;
